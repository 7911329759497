<template>
  <div class="container-fluid navbar-strip sticky-top">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-strip pxh-5">
        <div class="navbar-nav">
          <a class="nav-item nav-link fs-5 fw-bold text-light" href="/">Home</a>
        </div>
      </nav>
    </div>
  </div>
</template>



<script>
export default {
  name: 'NavBar'
}

</script>

<style>
.navbar-strip {
  background-color: #343e38;
}
</style>