<template>
    <NavBar />
    <section class="py-5 text-center container main-section">
        <div class="row py-lg-5 h-100 align-items-center">
            <div class="col-lg-6 col-md-8 mx-auto">
                <p class="mb-4 lead text-description">Find titles of movies you can't seem to remember. <br> Search for movies by short description or plot summary.</p>
                <form @submit.prevent="search_movie" class="text-center">
                    <!-- <div class="mb-1">
                        <input type="text" class="form-control" id="formGroupExampleInput2"
                            placeholder="Enter short description or plot summary" v-model="search_query" required
                            maxlength="200" rows="5">
                    </div>
                    <button type="submit" class="btn btn-secondary px-3 mx-1">Search</button> -->
                    <div class="input-group mb-3">
                        <input type="text" class="form-control shadow-none"
                            placeholder="Describe your movie..." v-model="search_query" required
                            minlength="50" maxlength="200">
                        <button class="btn btn-secondary" type="submit">Search</button>
                    </div>
                </form>
                <p class="lead text">
                    Example: A movie where a guy had to steal a lot of cars in one day. <br> <code>&#8212;</code> <span class="fw-normal"> Gone in 60 seconds (2000)</span>
                </p>
            </div>
        </div>
    </section>
</template>




<script>
import NavBar from "@/components/NavBar.vue";


export default {
    name: 'HomeVue',
    components: { NavBar },
    data() {
        return {
            movies: [],
            search_query: "",
        }
    },
    methods: {
        search_movie() {
            this.$router.push({ name: 'search-results', query: { q: this.search_query } })
        },
    },
}
</script>

<style scoped>
.btn {
    background-color: #343e38;
    color: white;
}

.btn:hover {
    background-color: #343e38;
}


input::placeholder {
  font-size: 15px;
}

.main-section {
    height: 90%;
}

.text {
    font-size: 13.5px;
}

@media (max-width: 576px) {
    .text {
        font-size: 11px;
    }
}

@media (max-width: 576px) {
    .text-description {
        font-size: 14px;
    }
}
</style>