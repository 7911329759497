<template>
    <NavBar />
    <section class="container py-5 mt-3">
        <div class="fs-5 mt-2 mb-4 fw-bold">Search Results</div>
        <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!loading" class="row row-cols-1 row-cols-sm-2 row-cols-md-6 row-cols-xs-1 g-3">
            <MovieCard v-for="(movie, index) in search_results" :title="movie.title" :year="movie.year"
                :image_url="movie.poster_url" :tmdb_id="movie.tmdb_id" :key="index" />
        </div>
    </section>
</template>




<script>

import axios from "axios"
import NavBar from "@/components/NavBar.vue";
import MovieCard from '@/components/MovieCard.vue';


export default {
    name: 'SearchResults',
    components: { MovieCard, NavBar },
    data() {
        return {
            search_results: [],
            loading: true,
        }
    },
    methods: {
        async search_movie(search_query) {
            axios({
                method: "get",
                url: `/search/?query=${search_query}`,
            }).then((response) => {
                this.search_results = response.data.results
                this.loading = false
            }).catch(error => {
                console.log(error)
            })
        },
    },

    created() {
        let search_query = this.$route.query.q
        if (!search_query) {
            this.$router.push({ name: 'home' })
        }
        this.search_movie(search_query)
    }

}
</script>


<style>
.movie-details {
    background-size: cover;

}
</style>