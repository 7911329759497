import { createApp } from "vue";
import App from "./App.vue";

import axios from "axios";
import router from "./router";

import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";


axios.defaults.baseURL = process.env.VUE_APP_BASEURL


createApp(App).use(router, axios).mount("#app");
