<template>
    <div class="col-6 col-lg-2 col-md-3 col-sm-6 text-center">
        <a :href="link" class="movie-link">
            <img class="img-fluid rounded mx-auto" :src="image_url" alt="" style="width: 200px; height: 300px;">
            <p class="text-center text-black pt-2 small movie-title"> {{ title }} ({{ year }})</p>
        </a>
    </div>
</template>



<script>


export default {
    name: 'MovieCard',
    props: {
        title: String,
        image_url: {
            type: String,
            default: "https://placehold.co/300x400"
        },
        year: Number,
        tmdb_id: String
    },
    methods: {

    },
    computed: {
        link: function () {
            return `/movies/${this.tmdb_id}/`
        }
    }

}
</script>

<style>
.movie-title {
    color: black;
}

.movie-link {
    text-decoration: none;
}
</style>