<template>
    <NavBar />
    <section class="container py-5 mt-3">
        <div v-if="detail_loading" class="d-flex justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!detail_loading" class="row py-lg-5 mx-1 movie-details"
            :style="{ backgroundImage: 'linear-gradient(to bottom, rgba(31, 29, 30, 0.555), rgba(245, 246, 252, 0.52)), url( ' + movie_detail.backdrop_url + ' )' }">
            <div class="col-lg-6 col-md-8 mx-auto">
                <div class="card mb-3 bg-transparent text-white border-0">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img :src="movie_detail.poster_url" class="img-fluid rounded" alt="...">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">{{ movie_detail.title }} ({{ movie_detail.year }})</h5>
                                <p class="card-text">{{ movie_detail.overview }}</p>
                                <p class="card-text"><span v-for="(genre, index) in movie_detail.movie_genres" :key="index"
                                        class="badge rounded-pill text-bg-dark" style="margin-right: 2px;">{{ genre
                                        }}</span></p>
                                <p class="card-text" style="cursor: pointer">
                                    <span @click="get_trailer_id" class=" text-bg-darkj btn btn-sm btn-danger"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        watch trailer <i class="bi bi-play-fill px-1 pt-2"></i>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="similar_movies_loading">
            <div class="d-flex justify-content-center">
                <div class="spinner-grow" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>
        <template v-if="!similar_movies_loading">
            <div class="fs-5 mt-5 mb-4 fw-bold">Similar Movies</div>
            <!-- <div class="row row-cols-6 row-cols-sm-2 row-cols-md-6 g-3"> -->
            <div class="row g-2">
                <MovieCard v-for="(movie, index) in similar_movies" :title="movie.title" :year="movie.year"
                    :image_url="movie.poster_url" :tmdb_id="movie.tmdb_id" :key="index" />
            </div>
        </template>

        <!-- modal -->
        <div class="modal fade modal-lg" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Trailer</h5>
                        <button type="button" @click="close_modal" class="btn-close" id="trailer-modal"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <i v-show="yt_loading" class="spinner-border spinner-border-sm"></i>
                        <iframe v-show="!yt_loading" class="w-100" :src="yt_trailer_url" width="300" height="400"
                            frameborder="0" allowfullscreen></iframe>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>




<script>

import axios from "axios"
import NavBar from "@/components/NavBar.vue";
import MovieCard from '@/components/MovieCard.vue';


export default {
    name: 'MovieDetail',
    components: { MovieCard, NavBar },
    data() {
        return {
            movie_detail: {},
            similar_movies: [],
            yt_loading: true,
            detail_loading: true,
            similar_movies_loading: true,
            yt_trailer_url: "",
        }
    },
    methods: {
        async get_movie_detail(tmdb_id) {
            axios({
                method: "get",
                url: `/${tmdb_id}/`,
            }).then((response) => {
                this.movie_detail = response.data
                this.detail_loading = false
            }).catch(error => {
                console.log(error)
            })
        },
        async get_similar_movies(tmdb_id) {
            axios({
                method: "get",
                url: `/${tmdb_id}/similar-movies/`,
            }).then((response) => {
                this.similar_movies = response.data.results
                this.similar_movies_loading = false
            }).catch(error => {
                console.log(error)
            })
        },
        async get_trailer_id() {
            this.loading = true
            axios({
                method: "get",
                url: `/youtube-trailer-id/${this.movie_detail.tmdb_id}/`,
            }).then((response) => {
                let trailer_id = response.data.trailer_id
                this.yt_trailer_url = `https://www.youtube.com/embed/${trailer_id}?&autoplay=1`
                this.yt_loading = false
            }).catch(error => {
                console.log(error)
            })
        },
        close_modal() {
            this.yt_trailer_url = ""
            document.getElementById('trailer-modal').click();
        }
    },

    created() {
        let tmdb_id = this.$route.params.tmdb_id
        this.get_movie_detail(tmdb_id)
        this.get_similar_movies(tmdb_id)
    }

}
</script>


<style>
.movie-details {
    background-size: cover;

}
</style>