import { createWebHistory, createRouter } from "vue-router"
import HomeVue from "@/views/Home.vue"
import MovieDetail from "@/views/MovieDetail.vue"
import SearchResults from "@/views/SearchResults.vue"
import LostPup from "@/views/404.vue"



const routes = [
  {
    path: "",
    name: "home",
    component: HomeVue,
  },
  {
    path: "/movies/:tmdb_id",
    name: "movie-detail",
    component: MovieDetail,
  },
  {
    path: "/search",
    name: "search-results",
    component: SearchResults,
  },
  {
    path: "/:pathMatch(.*)",
    name: "page-404",
    component: LostPup,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router 